import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Descriptions,
  notification,
} from 'antd';
import {LockOutlined, UploadOutlined} from '@ant-design/icons';
import { DatePicker, Upload } from 'antd';
import moment from 'moment';
import countries from 'i18n-iso-countries';
import portuguese from 'i18n-iso-countries/langs/pt.json';
import dayJS from 'dayjs';
const { Option } = Select;



const EditarUser = ({ dado }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [formPage, setFormPage] = useState('dsd');
  const [resultPage, setResultPage] = useState(null);
  const [formData, setFormData] = useState({});
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    getVars();
    if (dado) {
      setSelectedRoles(dado.groups.map((item, index) => item.id))
      form.setFieldsValue({
        nome: dado.first_user_name + " " + dado.last_user_name,
        genero: dado.gender,
        dataNascimento: moment(dado.birthday, "YYYY-MM-DD") || null,
        nacionalidade: dado.nationality,
        provincia: dado.province,
        distrito: dado.district,
        cidade: dado.city,
        instituicao: dado.provenance,
        profissao: dado.profession,
        contacto: dado.contact,
        email: dado.email,
        active: dado.active,
        acesso: dado.groups.map((item, index) => item.id),
      });
    }
  }, []);

  const getVars = async () => {
    Promise.all([
      axios.get(`${process.env.REACT_APP_API_URL}/api/user/role/`, {
        headers: {
          'Authorization': `Token ${sessionStorage.getItem('token')}`
        }
      }),
      // Adicione mais solicitações conforme necessário
    ]).then(([res1]) => {
      setRoles(res1.data);
    }).catch(err => {
      console.log(err);
    });
  }

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    setFormData(values);
    submit(values);
  };

  const submit = async (paciente) => {
    const data = {
      email: dado.email,
      first_user_name: form.getFieldValue('nome').split(' ').slice(0,-1).join(' '),
      last_user_name: form.getFieldValue('nome').split(' ').pop(),
      gender: form.getFieldValue('genero'),
      nationality: form.getFieldValue('nacionalidade'),
      province: form.getFieldValue('provincia'),
      district: form.getFieldValue('distrito'),
      city: form.getFieldValue('cidade'),
      profession: form.getFieldValue('profissao'),
      provenance: form.getFieldValue('instituicao'),
      contact: form.getFieldValue('contacto'),
      birthday: dayJS(form.getFieldValue('dataNascimento')).format('YYYY-MM-DD'),
      groups: selectedRoles,
      active: form.getFieldValue("active")
    }
    setLoading(true);
    axios
      .put(`${process.env.REACT_APP_API_URL}/api/user/${dado.id}/`, data, {
        headers: {
          'Authorization': `Token ${sessionStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        notification.success({
          message: "Sucesso!",
          description: "Informações Actualizadas Com Sucesso",
        });
      })
      .catch((err) => {
        notification.error({
          message: "Erro!",
          description: "Erro Ao Actualizar Informações",
        });
      }).finally(() => {
        setLoading(false);
      });
  }

  const handleDateChange = date => {
    setSelectedDate(date);
  };


  return (
    <div className='flex flex-col gap-4'>
      <Form
          form={form}
          name="basic"
          initialValues={{remember: true}}
          onFinish={onFinish}
          className="pt-0 pb-0 w-full h-full grid grid-cols-1 sm:grid-cols-2 gap-3 mt-3"
          scrollToFirstError
          style={{borderRadius: '15px'}}
      >
        <Form.Item
            name="nome"
            label="Nome"
            labelCol={{span: 24}}
            style={{margin: 0, padding: 0}}
            rules={[{required: true, message: 'Por favor insira o nome'}]}
        >
          <Input placeholder='Insira o Nome'/>
        </Form.Item>
        <Form.Item
            name="genero"
            label="Gênero"
            labelCol={{span: 24}}
            style={{margin: 0, padding: 0}}
            rules={[{required: true, message: 'Por favor seleccione o género'}]}
        >
          <Select placeholder="Seleccione o Género">
            <Option value="masculino">Masculino</Option>
            <Option value="feminino">Feminino</Option>
          </Select>
        </Form.Item>
        <Form.Item
            name="dataNascimento"
            label="Data de Nascimento"
            labelCol={{span: 24}}
            style={{margin: 0, padding: 0}}
            rules={[{required: true, message: 'Por favor insira a data de nascimento'}]}
        >
          <DatePicker
              style={{width: '100%'}}
              format="DD/MM/YYYY"
              // onChange={handleDateChange}
              // value={selectedDate}
          />
        </Form.Item>
        <Form.Item
            name="nacionalidade"
            label="Nacionalidade"
            labelCol={{span: 24}}
            style={{margin: 0, padding: 0}}
            rules={[{required: true, message: 'Por favor seleccione a nacionalidade'}]}
        >
          <Input placeholder='Insira a Nacionalidade'/>
        </Form.Item>
        <Form.Item
            name="provincia"
            label="Província"
            labelCol={{span: 24}}
            style={{margin: 0, padding: 0}}
            rules={[{required: true, message: 'Por favor seleccione a província'}]}
        >
          <Input placeholder='Insira a Província'/>
        </Form.Item>
        <Form.Item
            name="distrito"
            label="Distrito"
            labelCol={{span: 24}}
            style={{margin: 0, padding: 0}}
            rules={[{required: true, message: 'Por favor seleccione o distrito'}]}
        >
          <Input placeholder='Insira o Distrito'/>
        </Form.Item>
        <Form.Item
            name="cidade"
            label="Cidade"
            labelCol={{span: 24}}
            style={{margin: 0, padding: 0}}
            rules={[{required: true, message: 'Por favor seleccione a cidade'}]}
        >
          <Input placeholder='Insira a Cidade'/>
        </Form.Item>
        <Form.Item
            name="instituicao"
            label="Insituição"
            labelCol={{span: 24}}
            style={{margin: 0, padding: 0}}
            rules={[{required: true, message: 'Por favor seleccione a instituição'}]}
        >
          <Input placeholder='Insira a Instituição'/>
        </Form.Item>
        <Form.Item
            name="profissao"
            label="Profissão"
            labelCol={{span: 24}}
            style={{margin: 0, padding: 0}}
            rules={[{required: true, message: 'Por favor seleccione a profissão'}]}
        >
          <Input placeholder='Seleccione a Profissão'/>
        </Form.Item>
        <Form.Item
            name="contacto"
            label="Contacto"
            labelCol={{span: 24}}
            style={{margin: 0, padding: 0}}
            rules={[
              {len: 9, message: 'O contacto Inválido'},
            ]}
        >
          <Input
              addonBefore="+258"
              placeholder='Insira o Contacto'
          />
        </Form.Item>
        <Form.Item
            name="email"
            label="Email"
            labelCol={{span: 24}}
            style={{margin: 0, padding: 0}}
            rules={[
              {type: 'email', message: 'O email Inválido'},
              {required: true, message: 'Por favor insira o email'}
            ]}
        >
          <Input disabled placeholder='Insira o Email'/>
        </Form.Item>
        <Form.Item
            name="acesso"
            label="Acesso"
            labelCol={{span: 24}}
            style={{margin: 0, padding: 0}}
            rules={[
              {required: true, message: 'Por favor seleccione o acesso'},
            ]}
        >
          <Select
              placeholder="Seleccione o Acesso"
              mode="multiple"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={selectedRoles}
              onChange={(value) => setSelectedRoles(value)}
          >
            {roles.map((item, index) => (
                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
            name="active"
            valuePropName="checked"
        >
          <Checkbox>Ativo</Checkbox>
        </Form.Item>
        <Form.Item style={{ textAlign: 'right' }}>
          <Button htmlType="submit" loading={loading} className='bg-blue-600 text-white font-bold'>
            Editar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default EditarUser;