import React from 'react';
import ReactDOM from 'react-dom/client';
import {QueryClient, QueryClientProvider} from 'react-query';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Login from './Pages/Account/signin';
import MainLayout from './Layouts/MainLayout';
import Menu from './Pages/Menu';
import UserManagment from './Pages/UserManagment/Layout';
import CategoryLayout from './Pages/CategoriesManagment/Layout';
import InstitutionManagment from './Pages/InstitutionManagment/Layout';
import ProfileLayout from './Pages/Account/profile';
import Signup from './Pages/Account/signup';
import Recover from './Pages/Account/recoverpassword';

const router = createBrowserRouter([
  {
    path: '/account/login',
    element: <Login />
  },
  {
    path: '/account/signup',
    element: <Signup />
  },
  {
    path: '/account/resetpass',
    element: <Recover />
  },
  {
    path: '/',
    element: <MainLayout/>,
    children:[
      {
        path:'/',
        element: <Menu />,
      },
      {
        path:'/usuarios',
        element: <UserManagment/>,
      },
      {
        path:'/categorias',
        element: <CategoryLayout />,
      },
      {
        path:'/instituicoes',
        element: <InstitutionManagment />,
      },
      {
        path:'/perfil',
        element: <ProfileLayout />,
      }
    ]
  },
]);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
