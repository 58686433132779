import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaNotesMedical, FaPlus } from 'react-icons/fa';
import { MdPeople } from 'react-icons/md';
import { Input, Button, Menu, Space, Table, Divider, notification, Modal, Collapse, List, Form } from 'antd';
import { PlusOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import NovoUser from '../../Components/User/NovoUser';
import EditarUser from '../../Components/User/EditarUser';

const { Panel } = Collapse;

function CategoryLayout() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [markedPage, setMarkedPage] = useState(true);
  const [realizedPage, setRealizedPage] = useState(null);
  const [modalNew, setModalNew] = useState(null)
  const [modalAddSub, setModalAddSub] = useState(null)
  const [modalEditSub, setModalEditSub] = useState(null)
  const [modalEdit, setModalEdit] = useState(null)
  const [modalDeactivate, setModalDeactivate] = useState(null)
  const [modalDeactivate2, setModalDeactivate2] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalKey, setModalKey] = useState(Date.now());

  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();

  const handleAddCategory = (values) => {
    console.log(values);
    handleModalAdd();
  }

  const handleEditCategory = (values) => {
    console.log(values);
    setModalEdit(!modalEdit);
  }

  const handleAddSub= (values) => {
    console.log(values);
    setModalAddSub(!modalAddSub);
  }

  const handleEditSub = (values) => {
    console.log(values);
    setModalEditSub(!modalEditSub);
  }

  const [searchValue, setSearchValue] = useState({
    nid: '',
    nome: '',
    contacto: '',
  });

  useEffect(() => {
    localStorage.setItem('title', 'Gestão de Categorias');
    localStorage.setItem('type', '2');
  }, [])

  //Add New Profile
  const handleModalAdd = () => {
    formAdd.resetFields();
    setModalNew(!modalNew)
  }

  const handleModalSub = () => {
    formAdd.resetFields();
    setModalAddSub(!modalAddSub)
  }

  const goEditCategory = (category) => {
    try {
      formEdit.setFieldsValue(category);
      setSelectedRow(category);
    } finally {
      setModalEdit(!modalEdit);
    }
  }

  const handleDeleteCategory = (category) => {
    console.log(category);
    setModalDeactivate(!modalDeactivate);
  }

  const goEditSubCategory = (category) => {
    try {
      formEdit.setFieldsValue(category);
      setSelectedRow(category);
    } finally {
      setModalEditSub(!modalEditSub);
    }
  }

  const handleDeleteSubCategory = (category) => {
    console.log(category);
    setModalDeactivate2(!modalDeactivate2);
  }

  const handleCollapse = (key) => {
    console.log(key);
  }

  const goAddCategory = () => {
    setModalNew(!modalNew);
  }

  const [categories, setCategories] = useState([
    {
      "id": 1,
      "nome": "Epidemiologia e Saúde Pública",
      "subCategories": [
        {
          "id": 1,
          "nome": "Vigilância e inquéritos de saúde"
        },
        {
          "id": 2,
          "nome": "Surtos, resposta a emergências de saúde pública e populações deslocadas"
        },
        {
          "id": 3,
          "nome": "Estudos de prevalência e incidência"
        },
        {
          "id": 4,
          "nome": "Pesquisa de implementação e avaliações programáticas"
        },
        {
          "id": 5,
          "nome": "Acesso, prestação e utilização de serviços de saúde"
        },
        {
          "id": 6,
          "nome": "Sistemas de informação em saúde"
        },
        {
          "id": 7,
          "nome": "Determinantes de saúde"
        },
        {
          "id": 8,
          "nome": "Políticas de saúde"
        },
        {
          "id": 9,
          "nome": "Laboratório e sistemas de gestão da qualidade laboratorial"
        },
        {
          "id": 10,
          "nome": "Economia em saúde e estudos de custo-efetividade"
        }
      ]
    },
    {
      "id": 2,
      "nome": "Ciências Clínicas e Biomédicas",
      "subCategories": [
        {
          "id": 1,
          "nome": "Casos clínicos"
        },
        {
          "id": 2,
          "nome": "Ensaios clínicos"
        },
        {
          "id": 3,
          "nome": "Pesquisa clínica"
        },
        {
          "id": 4,
          "nome": "Farmacovigilância e resistência aos fármacos"
        },
        {
          "id": 5,
          "nome": "Patogênese, pesquisa básica, estudos genéticos e estudos animais"
        },
        {
          "id": 6,
          "nome": "Ética em pesquisa"
        }
      ]
    },
    {
      "id": 3,
      "nome": "Ciências Sociais e Comportamentais",
      "subCategories": [
        {
          "id": 1,
          "nome": "Direitos Humanos e legislação em saúde"
        },
        {
          "id": 2,
          "nome": "Pesquisa comportamental e social"
        },
        {
          "id": 3,
          "nome": "Educação, promoção e comunicação para a Saúde, Saúde Digital"
        },
        {
          "id": 4,
          "nome": "Engajamento comunitário e programas de saúde comunitária"
        }
      ]
    },
    {
      "id": 4,
      "nome": "Clima, Ambiente e Saúde",
      "subCategories": [
        {
          "id": 1,
          "nome": "Clima e Saúde"
        },
        {
          "id": 2,
          "nome": "Ambiente e saneamento"
        },
        {
          "id": 3,
          "nome": "Zoonoses"
        },
        {
          "id": 4,
          "nome": "Preparação e resposta a desastres naturais"
        }
      ]
    }
  ]);


  return (
    <div className='flex flex-col gap-4'>
      <div className="flex flex-col gap-2 bg-white rounded-lg p-4">
        <div className="flex flex-col items-center sm:flex-row gap-2">
          
          <Button onClick={handleModalAdd} icon={<FaPlus />} className="flex items-center text-white font-bold ml-auto mt-2 sm:mt-0" style={{ backgroundColor: '#00728a' }}>
            Nova Categoria
          </Button>
        </div>
        <Divider className='my-0' />
        <Collapse defaultActiveKey={['0']} onChange={handleCollapse}>
          {categories.map((category) => (
            <Panel
              header={category.nome}
              key={category.id}
              extra={
                <Space size="middle">
                  <Button
                    size="small"
                    style={{ backgroundColor: '#00728a'}}
                    className="text-white flex items-center"
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      goEditCategory(category);
                    }}
                  >
                    <EditOutlined className='text-white' size={35} />
                  </Button>
                  <Button
                    size="small"
                    style={{ backgroundColor: '#00728a' }}
                    className="text-white flex items-center"
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleModalSub();
                    }}
                  >
                    <PlusOutlined className='text-white' size={35} />
                  </Button>
                  <Button
                    size="small"
                    className="text-white bg-gray-600 flex items-center"
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCategory(category);
                    }}
                  >
                    <CloseOutlined className='text-white' size={35} />
                  </Button>
                </Space>
              }
            >
              <List
                size="small"
                bordered
                dataSource={category.subCategories}
                renderItem={
                  item => (
                    <List.Item
                      actions={[
                        <a key="list-loadmore-edit" onClick={()=>goEditSubCategory(item)}>Editar</a>, <a key="list-loadmore-more" onClick={()=>handleDeleteSubCategory(item)}>Eliminar</a>
                      ]}
                    >
                      <List.Item.Meta
                        title={<p style={{ fontSize: '0.85em' }}>{item.nome}</p>}
                      />
                    </List.Item>
                  )
                }
              />

            </Panel>
          ))}

        </Collapse>
        <Modal
          title="Nova Categoria"
          visible={modalNew}
          onCancel={handleModalAdd}
          width={500}
          footer={null}
        >
          <Form
            layout="vertical"
            form={formAdd}
            onFinish={handleAddCategory}
          >
            <Form.Item
              name="nome"
              label="Nome"
              rules={[{ required: true, message: 'Por favor insira o nome da categoria' }]}
            >
              <Input placeholder='Insira o Nome da Categoria' />
            </Form.Item>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button htmlType="submit" loading={loading} className='bg-blue-600 text-white font-bold'>
                Registar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Editar Categoria"
          visible={modalEdit}
          onCancel={() => setModalEdit(!modalEdit)}
          width={500}
          footer={null}
        >
          <Form
            layout="vertical"
            form={formEdit}
            onFinish={handleEditCategory}
          >
            <Form.Item
              name="nome"
              label="Nome"
              rules={[{ required: true, message: 'Por favor insira o nome da categoria' }]}
            >
              <Input placeholder='Insira o Nome da Categoria' />
            </Form.Item>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button htmlType="submit" loading={loading} className='bg-blue-600 text-white font-bold'>
                Editar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Adcionar Sub-Categoria"
          visible={modalAddSub}
          onCancel={handleModalSub}
          width={500}
          footer={null}
        >
          <Form
            layout="vertical"
            form={formAdd}
            onFinish={handleAddCategory}
          >
            <Form.Item
              name="nome"
              label="Nome"
              rules={[{ required: true, message: 'Por favor insira o nome da sub categoria' }]}
            >
              <Input placeholder='Insira o Nome da Sub-Categoria' />
            </Form.Item>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button htmlType="submit" loading={loading} className='bg-blue-600 text-white font-bold'>
                Registar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Editar Sub-Categoria"
          visible={modalEditSub}
          onCancel={handleEditSub}
          width={500}
          footer={null}
        >
          <Form
            layout="vertical"
            form={formEdit}
            onFinish={handleAddCategory}
          >
            <Form.Item
              name="nome"
              label="Nome"
              rules={[{ required: true, message: 'Por favor insira o nome da sub categoria' }]}
            >
              <Input placeholder='Insira o Nome da Sub-Categoria' />
            </Form.Item>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button htmlType="submit" loading={loading} className='bg-blue-600 text-white font-bold'>
                Editar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Deletar Categoria"
          centered
          visible={modalDeactivate}
          onOk={() => console.log('Desactivar')}
          onCancel={() => setModalDeactivate(!modalDeactivate)}
          okButtonProps={{
            style: {
              backgroundColor: '#00728a',
              borderColor: '#00728a',
            },
          }}
          okText="Deletar"
          cancelText="Cancelar"
        >
          <p>Deseja deletar a Categoria?</p>
        </Modal>
        <Modal
          title="Deletar Sub-Categoria"
          centered
          visible={modalDeactivate2}
          onOk={() => console.log('Desactivar')}
          onCancel={() => setModalDeactivate2(!modalDeactivate2)}
          okButtonProps={{
            style: {
              backgroundColor: '#00728a',
              borderColor: '#00728a',
            },
          }}
          okText="Deletar"
          cancelText="Cancelar"
        >
          <p>Deseja deletar a Sub-Categoria?</p>
        </Modal>
      </div>
    </div>
  );
}

export default CategoryLayout;