import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
} from 'antd';
import { DatePicker, Upload } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import countries from 'i18n-iso-countries';
import portuguese from 'i18n-iso-countries/langs/pt.json';
import { el } from 'date-fns/locale';
import dayJS from 'dayjs';

const { Option } = Select;

const NovoUser = ({ option, close }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [formPage, setFormPage] = useState('dsd');
  const [resultPage, setResultPage] = useState(null);
  const [formData, setFormData] = useState({});
  const [modalConsult, setModalConsult] = useState(false);
  const [modalKey, setModalKey] = useState(); //This is to force the modal to re-render
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([])

  useEffect(() => {
    getVars();
  }, []);



  const getVars = async () => {
    Promise.all([
      axios.get(`${process.env.REACT_APP_API_URL}/api/user/role/`, {
        headers: {
          'Authorization': `Token ${sessionStorage.getItem('token')}`
        }
      }),
      // Adicione mais solicitações conforme necessário
    ]).then(([res1]) => {
      setRoles(res1.data);
    }).catch(err => {
      console.log(err);
    });
  }

  const goConsult = () => {
    setModalKey(Date.now());
    setModalConsult(true);
  }

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    setFormData(values);
    submit(values)
  };

  const submit = async (data) => {
    let url= `${process.env.REACT_APP_API_URL}/api/user/create/`
    // if (data.acesso === 'ADMIN') {
    //   url = `${process.env.REACT_APP_API_URL}/api/user/admin/`
    // } else if (data.acesso === 'SECRETARIADO') {
    //   url = `${process.env.REACT_APP_API_URL}/api/user/secretary/`
    // } else if (data.acesso === 'REVISOR') {
    //   url = `${process.env.REACT_APP_API_URL}/api/user/reviewer/`
    // }
    setLoading(true)
    axios.post(url, {
      email: form.getFieldValue('email'),
      password: form.getFieldValue('password'),
      first_user_name: form.getFieldValue('nome').split(' ').shift(),
      last_user_name: form.getFieldValue('nome').split(' ').pop(),
      gender: form.getFieldValue('genero'),
      nationality: form.getFieldValue('nacionalidade'),
      province: form.getFieldValue('provincia'),
      district: form.getFieldValue('distrito'),
      city: form.getFieldValue('cidade'),
      profession: form.getFieldValue('profissao'),
      provenance: form.getFieldValue('instituicao'),
      contact: form.getFieldValue('contacto'),
      birthday: dayJS(form.getFieldValue('dataNascimento')).format('YYYY-MM-DD'),
      groups: selectedRoles
    }, {
      headers: {
        'Authorization': `Token ${sessionStorage.getItem('token')}`
      }
    })
      .then(res => {
        console.log(res.data);
        setFormPage(false)
        close()
      })
      .catch(err => {
        console.log(err);
      }).finally(() => {
        setLoading(false)
      });
  }
    

  
  return (
    <div className='flex flex-col gap-4'>
      {formPage && <>
        <Form
            form={form}
            name="basic"
            initialValues={{remember: true}}
            onFinish={onFinish}
            className="pt-0 pb-0 w-full h-full grid grid-cols-1 sm:grid-cols-2 gap-3 mt-3"
            scrollToFirstError
            style={{borderRadius: '15px'}}
        >
          <Form.Item
              name="nome"
              label="Nome"
              labelCol={{span: 24}}
              style={{margin: 0, padding: 0}}
              rules={[{required: true, message: 'Por favor insira o nome'}]}
          >
            <Input placeholder='Insira o Nome'/>
          </Form.Item>
          <Form.Item
              name="genero"
              label="Gênero"
              labelCol={{span: 24}}
              style={{margin: 0, padding: 0}}
              rules={[{required: true, message: 'Por favor seleccione o género'}]}
          >
            <Select placeholder="Seleccione o Género">
              <Option value="masculino">Masculino</Option>
              <Option value="feminino">Feminino</Option>
            </Select>
          </Form.Item>
          <Form.Item
              name="dataNascimento"
              label="Data de Nascimento"
              labelCol={{span: 24}}
              style={{margin: 0, padding: 0}}
              rules={[{required: true, message: 'Por favor insira a data de nascimento'}]}
          >
            <DatePicker style={{width: '100%'}} format="DD/MM/YYYY"/>
          </Form.Item>
          <Form.Item
              name="nacionalidade"
              label="Nacionalidade"
              labelCol={{span: 24}}
              style={{margin: 0, padding: 0}}
              rules={[{required: true, message: 'Por favor seleccione a nacionalidade'}]}
          >
            <Input placeholder='Insira a Nacionalidade'/>
          </Form.Item>
          <Form.Item
              name="provincia"
              label="Província"
              labelCol={{span: 24}}
              style={{margin: 0, padding: 0}}
              rules={[{required: true, message: 'Por favor seleccione a província'}]}
          >
            <Input placeholder='Insira a Província'/>
          </Form.Item>
          <Form.Item
              name="distrito"
              label="Distrito"
              labelCol={{span: 24}}
              style={{margin: 0, padding: 0}}
              rules={[{required: true, message: 'Por favor seleccione o distrito'}]}
          >
            <Input placeholder='Insira o Distrito'/>
          </Form.Item>
          <Form.Item
              name="cidade"
              label="Cidade"
              labelCol={{span: 24}}
              style={{margin: 0, padding: 0}}
              rules={[{required: true, message: 'Por favor seleccione a cidade'}]}
          >
            <Input placeholder='Insira a Cidade'/>
          </Form.Item>
          <Form.Item
              name="instituicao"
              label="Insituição"
              labelCol={{span: 24}}
              style={{margin: 0, padding: 0}}
              rules={[{required: true, message: 'Por favor seleccione a instituição'}]}
          >
            <Input placeholder='Insira a Instituição'/>
          </Form.Item>
          <Form.Item
              name="profissao"
              label="Profissão"
              labelCol={{span: 24}}
              style={{margin: 0, padding: 0}}
              rules={[{required: true, message: 'Por favor seleccione a profissão'}]}
          >
            <Input placeholder='Seleccione a Profissão'/>
          </Form.Item>
          <Form.Item
              name="contacto"
              label="Contacto"
              labelCol={{span: 24}}
              style={{margin: 0, padding: 0}}
              rules={[
                {len: 9, message: 'O contacto Inválido'},
              ]}
          >
            <Input
                addonBefore="+258"
                placeholder='Insira o Contacto'
            />
          </Form.Item>
          <Form.Item
              name="email"
              label="Email"
              labelCol={{span: 24}}
              style={{margin: 0, padding: 0}}
              rules={[
                {type: 'email', message: 'O email Inválido'},
                {required: true, message: 'Por favor insira o email'}
              ]}
          >
            <Input placeholder='Insira o Email'/>
          </Form.Item>
          <Form.Item
              name="password"
              label="Senha"
              labelCol={{span: 24}}
              style={{margin: 0, padding: 0}}
              rules={[{required: true, message: 'Introduza a sua senha!'}]}
          >
            <Input.Password
                prefix={<LockOutlined className="site-form-item-icon"/>}
                placeholder="Senha"
            />
          </Form.Item>
          <Form.Item
              name="acesso"
              label="Acesso"
              labelCol={{span: 24}}
              style={{margin: 0, padding: 0}}
              rules={[
                {required: true, message: 'Por favor seleccione o acesso'},
              ]}
          >
            <Select
                placeholder="Seleccione o Acesso"
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={selectedRoles}
                onChange={(value) => setSelectedRoles(value)}
            >
              {roles.map((item, index) => (
                  <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div></div>
          <div></div>
          <Form.Item
              style={{width: '100%'}}
              labelAlign='right'
              labelCol={24}
              className="flex justify-end"
          >
            <Button htmlType="submit" loading={loading} className='bg-blue-600 text-white font-bold'>
              Registar
            </Button>
          </Form.Item>
        </Form>
      </>}
    </div>
  );
}

export default NovoUser;