import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaNotesMedical, FaPlus } from 'react-icons/fa';
import { MdPeople } from 'react-icons/md';
import { Input, Button, Menu, Space, Table, Divider, notification, Modal, Tag } from 'antd';
import { CheckOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import NovoUser from '../../Components/User/NovoUser';
import EditarUser from '../../Components/User/EditarUser';
import { MdDelete } from 'react-icons/md';

function UserManagment() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [markedPage, setMarkedPage] = useState(true);
  const [realizedPage, setRealizedPage] = useState(null);
  const [modalNew, setModalNew] = useState(null)
  const [modalEdit, setModalEdit] = useState(null)
  const [modalDeactivate, setModalDeactivate] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalKey, setModalKey] = useState(Date.now());

  const [searchValue, setSearchValue] = useState({
    nid: '',
    nome: '',
    contacto: '',
  });

  useEffect(() => {
    localStorage.setItem('title', 'Gestão de Perfis');
    localStorage.setItem('type', '2');
    getUsers();
  }, [])

  const getUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/`, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem('token')}`
        }
      });
      setDataMarked(response.data);
      setLoading(false);
      console.log(response.data);
    } catch (error) {
      setLoading(false);
      notification.error({
        message: 'Erro',
        description: 'Falha ao carregar usuários'
      });
    }
  }

  const handleRemoveReview = (data) => {
    setLoading(true);
    axios
        .delete(`${process.env.REACT_APP_API_URL}/api/user/${data.id}/`, {
          headers: {
            'Authorization': `Token ${sessionStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          getUsers();
          notification.success({
            message: "Sucesso!",
            description: "Usuario removido com sucesso",
          });
        })
        .catch((err) => {
          notification.error({
            message: "Erro!",
            description: "Erro Ao Remover Usuario",
          });
        }).finally(() => {
      setLoading(false);
    });
  }

  const columnsMarked = [
    {
      title: "ID",
      dataIndex: "id",
      width: 90,
      key: "id",
    },
    {
      title: "Nome",
      dataIndex: "name",
      render: (text, record) => (
        <span>{record.first_user_name} {record.last_user_name}</span>
      ),
    },
    {
      title: "Acesso (s)",
      dataIndex: "accesso",
      width: 180,
      render: (text, record) => (
        <span>{record.groups.map(group => group.name).join(', ')}</span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 260,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: 130,
      render: (text, record) => (
        <Tag color={record.active ? 'green' : 'red'}>
          {record.active ? 'Activo' : 'Desactivado'}
        </Tag>
      ),
    },
    {
      title: "Acções",
      key: "actions",
      width: 90,
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button size="small" style={{ backgroundColor: '#00728a' }} className="text-white flex items-center" type="link" onClick={() => goEdit(record)}>
            <EditOutlined className='text-white' size={35} />
          </Button>
          <Button onClick={() => handleRemoveReview(record)} className='bg-red-600' icon={<MdDelete color='white' />} size="small"/>
        </Space>
      ),
    },
  ];

  const [dataMarked, setDataMarked] = useState([]);

  const filteredMarked = dataMarked.filter((item) => {
    if (searchValue.nome && (item.first_user_name.toLowerCase().indexOf(searchValue.nome.toLowerCase()) === -1)) {
      return false;
    }
    return true;
  });

  const goEdit = (record) => {
    try {
      setModalKey(Date.now());
      setSelectedRow(record);
    } finally {
      setModalEdit(!modalEdit);
    }
  }

  const goDeactivate = (record) => {
    try {
      setModalKey(Date.now());
      setSelectedRow(record);
    } finally {
      setModalDeactivate(!modalDeactivate);
    }
  }

  //Add New Profile
  const handleModalAdd = () => {
    setModalKey(Date.now());
    setModalNew(!modalNew)
    getUsers()
  }

  return (
    <div className='flex flex-col gap-4'>
      <div className="flex flex-col gap-2 bg-white rounded-lg p-4">
        <div className="flex flex-col items-center sm:flex-row gap-2">
          <FaSearch />
          <Input
            placeholder="Pesquisa por Nome"
            value={searchValue.nome}
            className='sm:w-64'
            onChange={(e) => setSearchValue({ ...searchValue, nome: e.target.value })}
          />
          <Button onClick={handleModalAdd} icon={<FaPlus />} className="flex items-center text-white font-bold ml-auto mt-2 sm:mt-0" style={{ backgroundColor: '#00728a' }}>
            Novo Usuário
          </Button>
        </div>
        <Divider className='my-0' />
        {markedPage &&
          <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
            <Table
              size='middle'
              columns={columnsMarked}
              dataSource={filteredMarked}
              loading={loading}
              pagination={{ pageSize: 10 }}
            />
          </div>
        }
        <Modal
          title="Novo Usuário"
          visible={modalNew}
          onCancel={handleModalAdd}
          width={900}
          footer={null}
        >
          <NovoUser key={modalKey} close={handleModalAdd}/>
        </Modal>
        <Modal
          title="Editar Usuário"
          visible={modalEdit}
          onCancel={() => {
            setModalEdit(!modalEdit)
            getUsers()
          }}
          width={900}
          footer={null}
        >
          <EditarUser dado={selectedRow} key={modalKey}/>
        </Modal>
        <Modal
          title="Desactivar Usuário"
          centered
          visible={modalDeactivate}
          onOk={() => console.log('Desactivar')}
          onCancel={() => setModalDeactivate(!modalDeactivate)}
          okButtonProps={{
            style: {
              backgroundColor: '#00728a',
              borderColor: '#00728a',
            },
          }}
          okText="Desactivar"
          cancelText="Cancelar"
        >
          <p>Deseja desactivar o usuário?</p>
        </Modal>
      </div>
    </div>
  );
}

export default UserManagment;