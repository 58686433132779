import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaNotesMedical, FaPlus } from 'react-icons/fa';
import { MdPeople } from 'react-icons/md';
import { Input, Button, Menu, Space, Table, Divider, notification, Modal, Form } from 'antd';
import { CheckOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import NovoUser from '../../Components/User/NovoUser';
import EditarUser from '../../Components/User/EditarUser';

function InstitutionManagment() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [markedPage, setMarkedPage] = useState(true);
  const [realizedPage, setRealizedPage] = useState(null);
  const [modalNew, setModalNew] = useState(null)
  const [modalEdit, setModalEdit] = useState(null)
  const [modalDeactivate, setModalDeactivate] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalKey, setModalKey] = useState(Date.now());

  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();


  const [searchValue, setSearchValue] = useState({
    nid: '',
    nome: '',
    contacto: '',
  });

  useEffect(() => {
    localStorage.setItem('title', 'Instituições');
    localStorage.setItem('type', '2');
  }, [])

  const handleAddCategory = (values) => {
    console.log(values);
    handleModalAdd();
  }

  const handleEditCategory = (values) => {
    console.log(values);
    setModalEdit(!modalEdit);
  }

  const columnsMarked = [
    {
      title: "ID",
      dataIndex: "id",
      width: 90,
      key: "id",
    },
    {
      title: "Nome",
      dataIndex: "nome",
    },
    {
      title: "Acções",
      key: "actions",
      width: 140,
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button size="small" style={{ backgroundColor: '#00728a' }} className="text-white flex items-center" type="link" onClick={() => goEdit(record)}>
            <EditOutlined className='text-white' size={35} />
          </Button>
          <Button size="small" className="text-white bg-gray-600 flex items-center" type="link" onClick={() => goDeactivate(record)}>
            <CloseOutlined className='text-white' size={35} />
          </Button>
        </Space>
      ),
    },
  ];

  const [dataMarked, setDataMarked] = useState([
    {
      id: 1,
      nome: "Universidade Eduardo Mondlane",
    },
    {
      id: 2,
      nome: "Universidade Pedagógica",
    },
    {
      id: 3,
      nome: "Universidade São Tomás de Moçambique",
    },
  ]);

  const filteredMarked = dataMarked.filter((item) => {
    if (searchValue.nome && item.nome.toLowerCase().indexOf(searchValue.nome.toLowerCase()) === -1) {
      return false;
    }
    return true;
  });

  const goEdit = (category) => {
    try {
      formEdit.setFieldsValue(category);
      setSelectedRow(category);
    } finally {
      setModalEdit(!modalEdit);
    }
  }

  const goDeactivate = (record) => {
    try {
      setModalKey(Date.now());
      setSelectedRow(record);
    } finally {
      setModalDeactivate(!modalDeactivate);
    }
  }

  //Add New Profile
  const handleModalAdd = () => {
    formAdd.resetFields();
    setModalNew(!modalNew)
  }

  return (
    <div className='flex flex-col gap-4'>
      <div className="flex flex-col gap-2 bg-white rounded-lg p-4">
        <div className="flex flex-col items-center sm:flex-row gap-2">
          <FaSearch />
          <Input
            placeholder="Pesquisa por Nome"
            value={searchValue.nome}
            className='sm:w-64'
            onChange={(e) => setSearchValue({ ...searchValue, nome: e.target.value })}
          />
          <Button onClick={handleModalAdd} icon={<FaPlus />} className="flex items-center text-white font-bold ml-auto mt-2 sm:mt-0" style={{ backgroundColor: '#00728a' }}>
            Nova Instituição
          </Button>
        </div>
        <Divider className='my-0' />
        {markedPage &&
          <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
            <Table
              size='middle'
              columns={columnsMarked}
              dataSource={filteredMarked}
              loading={loading}
              pagination={{ pageSize: 10 }}
            />
          </div>
        }
        <Modal
          title="Nova Instituição"
          visible={modalNew}
          onCancel={handleModalAdd}
          width={500}
          footer={null}
        >
          <Form
            layout="vertical"
            form={formAdd}
            onFinish={handleAddCategory}
          >
            <Form.Item
              name="nome"
              label="Nome"
              rules={[{ required: true, message: 'Por favor insira o nome da Instituição' }]}
            >
              <Input placeholder='Insira o Nome da Instituição' />
            </Form.Item>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button htmlType="submit" loading={loading} className='bg-blue-600 text-white font-bold'>
                Registar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Editar Instituição"
          visible={modalEdit}
          onCancel={() => setModalEdit(!modalEdit)}
          width={500}
          footer={null}
        >
          <Form
            layout="vertical"
            form={formEdit}
            onFinish={handleEditCategory}
          >
            <Form.Item
              name="nome"
              label="Nome"
              rules={[{ required: true, message: 'Por favor insira o nome da Instituição' }]}
            >
              <Input placeholder='Insira o Nome da Instituição' />
            </Form.Item>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button htmlType="submit" loading={loading} className='bg-blue-600 text-white font-bold'>
                Editar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        
        <Modal
          title="Deletar Instituição"
          centered
          visible={modalDeactivate}
          onOk={() => console.log('Desactivar')}
          onCancel={() => setModalDeactivate(!modalDeactivate)}
          okButtonProps={{
            style: {
              backgroundColor: '#00728a',
              borderColor: '#00728a',
            },
          }}
          okText="Deletar"
          cancelText="Cancelar"
        >
          <p>Deseja deletar a Instituição?</p>
        </Modal>
      </div>
    </div>
  );
}

export default InstitutionManagment;